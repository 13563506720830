import React from "react";
import { db } from "../../Utils/firebase";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import { Button } from "@material-ui/core";
import { doc, deleteDoc, getDoc } from "firebase/firestore";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import FlipMove from "react-flip-move";
import CopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import { CopyToClipboard } from "react-copy-to-clipboard";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { ArrowRight } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    maxWidth: 550,
    background: "whitesmoke",
    margin: "15px auto",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const Admin = () => {
  const classes = useStyles();

  const [entries, setEntries] = React.useState([]);
  const [view, setView] = React.useState(false);
  const [q, setQ] = React.useState("");
  const [p, setP] = React.useState([]);
  const [pass, setPass] = React.useState("");
  const [verified, setVerified] = React.useState(false);
  const [filteredData, setFilteredData] = React.useState([]);
  React.useEffect(() => {
    if (q !== "") {
      const a = [];
      entries.forEach((entry) => {
        if (
          entry.data.name.toLowerCase().includes(q.toLowerCase()) ||
          entry.data.email.toLowerCase().includes(q.toLowerCase())
        ) {
          a.push(entry);
        }
        setFilteredData(a);
      });
    } else {
      setFilteredData(entries);
    }
  }, [q, entries]);

  React.useEffect(() => {
    async function fetchData() {
      const pa = await getDoc(doc(db, "passwords", "allowed"));
      setP(pa.data().list);
    }
    fetchData();
    const q = query(collection(db, "entries"), orderBy("timestamp", "desc"));
    onSnapshot(q, (querySnapshot) => {
      const c = [];
      querySnapshot.forEach((doc) => {
        c.push({ data: doc.data(), id: doc.id });
      });
      setEntries(c);
      setFilteredData(c);
    });
  }, []);
  return (
    <>
      {verified ? (
        <div>
          <center>
            <h1 style={{ fontSize: "3em" }}>Contact Us Submissions.</h1>
          </center>
          <Paper component="form" className={classes.root}>
            <InputBase
              className={classes.input}
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                setQ(e.target.value);
              }}
            />
            <IconButton className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
          <FlipMove>
            {filteredData.map((entry) => (
              <div key={entry.data.timestamp}>
                <Card
                  elevation={3}
                  style={{
                    background: "whitesmoke",
                    maxWidth: "500px",
                    padding: "15px",
                    margin: "15px auto",
                    borderRadius: "15px",
                  }}
                >
                  <CardContent>
                    Name:<h1> {entry.data.name}</h1>
                    <h3>
                      Email:{" "}
                      <a href={`mailto:${entry.data.email}`}>
                        {entry.data.email}
                      </a>
                      <CopyToClipboard
                        text={entry.data.email}
                        onCopy={() => {
                          alert(`${entry.data.email} copied to Clipboard!`);
                        }}
                      >
                        <IconButton>
                          <CopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                    </h3>
                    <h3>
                      Contact No.:{" "}
                      <a href={`tel:${entry.data.contact}`}>
                        {entry.data.contact}
                      </a>
                      <CopyToClipboard
                        text={entry.data.contact}
                        onCopy={() => {
                          alert(`${entry.data.contact} copied to Clipboard!`);
                        }}
                      >
                        <IconButton>
                          <CopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                    </h3>
                    <h3>Organization: {entry.data.org}</h3>
                    <br />
                    <h3>Message: {entry.data.message}</h3>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={async () => {
                        await deleteDoc(doc(db, "entries", entry.id));
                      }}
                      endIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>
                  </CardActions>
                </Card>
              </div>
            ))}
          </FlipMove>
        </div>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Paper component="form" className={classes.root}>
            <InputBase
              className={classes.input}
              placeholder="Password"
              type={view ? "text" : "password"}
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                setPass(e.target.value);
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  if (p.includes(pass)) {
                    setVerified(true);
                  } else {
                    alert("Wrong Password!");
                  }
                  ev.preventDefault();
                }
              }}
            />
            <IconButton
              className={classes.iconButton}
              aria-label="visibility"
              onClick={() => setView(!view)}
            >
              {view ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
            <IconButton
              className={classes.iconButton}
              aria-label="enter"
              onClick={() => {
                if (p.includes(pass)) {
                  setVerified(true);
                } else {
                  alert("Wrong Password!");
                }
              }}
            >
              <ArrowRight />
            </IconButton>
          </Paper>
        </div>
      )}
    </>
  );
};

export default Admin;
