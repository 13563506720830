// import React, { useState, useEffect } from "react";
// import { NavbarButton } from "../../AnimatedNavigationMenu/NavbarButton.js";
// import logoTitle from "../../images/labs_for_home.png";
// import logo from "../../images/Logo.svg";
// import { useHistory } from "react-router-dom";
// import { animateScroll as scroll } from "react-scroll";

// const Navbar = ({ isOpen, toggleOpen }) => {
//   const [scrollPosition, setScrollPosition] = useState(0);
//   const history = useHistory();
//   const handleScroll = () => {
//     const position = window.pageYOffset;
//     setScrollPosition(position);
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll, { passive: true });

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);
//   return (
//     <div>
//       {scrollPosition > 30 && (
//         <div
//           style={{
//             position: "fixed",
//             width: "100vw",
//             height: "80px",
//             background: "#141414dd",
//             top: "0",
//           }}
//         ></div>
//       )}
//       <NavbarButton isOpen={isOpen} toggleOpen={toggleOpen} />
//       <div
//         style={{
//           display: "flex",
//           position: "fixed",
//           alignItems: "center",
//           justifyContent: "center",
//           padding: "16.5px",
//           top: 0,
//           cursor: "pointer",
//         }}
//         onClick={() => {
//           history.push("/");
//           scroll.scrollToTop();
//         }}
//       >
//         <div
//           style={{
//             background: "#31313133",
//             marginLeft: "10px",
//             width: "40px",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             borderRadius: "50%",
//             padding: "5px",
//           }}
//         >
//           <img src={logo} style={{ height: "40px" }} alt="LabsFromHome Logo" />
//         </div>
//         <img
//           src={logoTitle}
//           style={{ height: "30px", marginLeft: "5px" }}
//           alt="LabsFromHome Title"
//           className='logotitle'
//         />
//       </div>
//     </div>
//   );
// };

// export default Navbar;


import React, { useState, useEffect } from "react";
import { NavbarButton } from "../../AnimatedNavigationMenu/NavbarButton.js";
import logoTitle from "../../images/labs_for_home_new.png";
import logo from "../../images/logo_s.png";
import { useHistory } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const Navbar = ({ isOpen, toggleOpen }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const history = useHistory();
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      {scrollPosition > 1 && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "80px",
            background: "#141414bb",
            top: "0",
          }}
        ></div>
      )}
      <NavbarButton isOpen={isOpen} toggleOpen={toggleOpen} />
      <div
        style={{
          display: "flex",
          position: "fixed",
          alignItems: "center",
          justifyContent: "center",
          padding: "5px",
          top: scrollPosition > 1 || isOpen ? 0 : 10,
          left: scrollPosition > 1 || isOpen ? 0 : 10,
          cursor: "pointer",
          background: `${
            scrollPosition > 1 || isOpen ? "#14141400" : "#14141488"
          }`,
          borderRadius: "20px",
        }}
        onClick={() => {
          history.push("/");
          scroll.scrollToTop();
        }}
      >
        <div
          style={{
            marginLeft: "10px",
            width: "90px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            padding: "5px",
          }}
        >
          <img src={logo} style={{ height: "60px" }} alt="LabsFromHome Logo" />
        </div>
        <img
          src={logoTitle}
          style={{ height: "60px"}}
          alt="LabsFromHome Title"
          className="logotitle"
        />
      </div>
    </div>
  );
};

export default Navbar;
