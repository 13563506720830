import React, { useState } from "react";
import "./ContactUs.css";
import emailjs from "emailjs-com";
import Navbar from "../Navbar/Navbar";
import { useCycle, motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { db } from "../../Utils/firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

// TODO: Add a form validation

const ContactUs = () => {
  const [view, setView] = useState(
    localStorage.getItem("contactUs") !== null ? false : true
  );
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [contact, setContact] = useState("");
  const [org, setOrg] = useState("Company/Organization");
  const [isOpen, toggleOpen] = useCycle(false, true);
  const history = useHistory();
  emailjs.init("user_ojqAAGpfC1oDNGaYGmIUk");
  return (
    <motion.div className="background-contact-us">
      {view ? (
        <motion.div className="form-container" layoutId="contactus-button">
          <div className="screen">
            <div className="screen-header">
              <div className="screen-header-left">
                <div className="screen-header-button close"></div>
                <div className="screen-header-button maximize"></div>
                <div className="screen-header-button minimize"></div>
              </div>
              <div className="screen-header-right">
                <div className="screen-header-ellipsis"></div>
                <div className="screen-header-ellipsis"></div>
                <div className="screen-header-ellipsis"></div>
              </div>
            </div>
            <div className="screen-body">
              <div className="screen-body-item left">
                <div className="app-title">
                  <span>CONTACT</span>
                  <span>US</span>
                </div>
                <div className="app-contact">LabsForHome</div>
              </div>
              <div className="screen-body-item">
                <div className="app-form">
                  <div className="app-form-group">
                    <input
                      className="app-form-control"
                      placeholder="NAME"
                      name="name"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="app-form-group">
                    <input
                      className="app-form-control"
                      placeholder="EMAIL"
                      type="email"
                      name="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                  <div className="app-form-group">
                    <input
                      className="app-form-control"
                      placeholder="CONTACT NO"
                      type="text"
                      name="contact"
                      onChange={(e) => {
                        setContact(e.target.value);
                      }}
                    />
                  </div>
                  <div className="app-form-group">
                    <select
                      id="organization"
                      className="app-form-control"
                      name="organization"
                      defaultValue="Company/Organization"
                      onChange={(e) => {
                        setOrg(e.target.value);
                      }}
                    >
                      <option value="Company/Organization">
                        Company/Organization
                      </option>
                      <option value="Individual">Individual</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="app-form-group message">
                    <input
                      className="app-form-control"
                      placeholder="MESSAGE"
                      type="text"
                      name="message"
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    />
                  </div>
                  <div data-netlify-recaptcha="true"></div>
                  <div className="app-form-group buttons">
                    <button
                      className="app-form-button"
                      onClick={() => {
                        history.goBack();
                      }}
                      type="button"
                    >
                      CANCEL
                    </button>
                    <button
                      className="app-form-button"
                      type="submit"
                      name="submit"
                      value="set"
                      onClick={async () => {
                        if (
                          ![name, email, message, contact, org].every(Boolean)
                        ) {
                          alert("Please fill out all fields!");
                        } else {
                          await addDoc(collection(db, "entries"), {
                            name: name,
                            email: email,
                            message: message,
                            contact: contact,
                            org: org,
                            timestamp: serverTimestamp(),
                          }).then(() => {
                            localStorage.setItem("contactUs", "submitted");
                            setView(false);
                            window.location.reload();
                          });
                        }
                      }}
                    >
                      SEND
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      ) : (
        <motion.div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100vw",
            height: "100vh",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
          <div
            className="heading"
            style={{
              color: "white",
              width: "80%",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            Thanks for getting in touch with us.
          </div>
          <motion.div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              history.push("/");
            }}
          >
            <Button
              color="secondary"
              variant="contained"
              className="read-more-button-cyan"
            >
              Return to home
            </Button>
          </motion.div>
        </motion.div>
      )}
      <Navbar isOpen={isOpen} toggleOpen={toggleOpen} />
    </motion.div>
  );
};

export default ContactUs;
