import vlabs from "../../images/vlabs.jpg";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { Button, Divider } from "@material-ui/core";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { useHistory } from "react-router-dom";

function FadeInWhenVisible({ children }) {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 0.5 }}
      variants={{
        visible: { scale: 1 },
        hidden: { scale: 1.2 },
      }}
    >
      {children}
    </motion.div>
  );
}

const VLabsComponent = ({ showReadMore }) => {
  const TimeLine = () => {
    return (
      <Timeline align="left">
        <TimelineItem>
          <TimelineSeparator>
            <FadeInWhenVisible>
              <TimelineDot color="secondary" />
            </FadeInWhenVisible>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            style={{
              fontSize: "1.5em",
              marginTop: "-7px",
              fontWeight: "500",
              letterSpacing: "1px",
            }}
          >
            <FadeInWhenVisible>50+ Experiments</FadeInWhenVisible>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <FadeInWhenVisible>
              <TimelineDot color="primary" />
            </FadeInWhenVisible>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            style={{
              fontSize: "1.5em",
              marginTop: "-7px",
              fontWeight: "500",
              letterSpacing: "1px",
            }}
          >
            <FadeInWhenVisible>
              Engaging competition based learning
            </FadeInWhenVisible>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <FadeInWhenVisible>
              <TimelineDot color="secondary" />
            </FadeInWhenVisible>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            style={{
              fontSize: "1.5em",
              marginTop: "-7px",
              fontWeight: "500",
              letterSpacing: "1px",
            }}
          >
            <FadeInWhenVisible>Theme based approach</FadeInWhenVisible>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <FadeInWhenVisible>
              <TimelineDot color="primary" />
            </FadeInWhenVisible>
          </TimelineSeparator>
          <TimelineContent
            style={{
              fontSize: "1.5em",
              marginTop: "-7px",
              fontWeight: "500",
              letterSpacing: "1px",
            }}
          >
            <FadeInWhenVisible>Affordable</FadeInWhenVisible>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    );
  };

  const history = useHistory();

  return (
    <div className="vlabsComponent">
      <div
        style={{
          height: "auto",
          width: "100vw",
          display: "flex",
        }}
        className="bodyComponent"
        id="vlabs"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="bodyComponentSmallerChild"
        >
          <center className="aboutusimage">
            <FadeInWhenVisible>
              <img src={vlabs} alt="svg" className="bodyComponentImage" />
            </FadeInWhenVisible>
          </center>
        </div>
        <div
          style={{
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="bodyComponentBiggerChild"
        >
          <div
            style={{
              background: "#14141452",
              borderRadius: "40px",
              margin: "30px",
              marginTop: `${!showReadMore ? "120px" : "0"}`,
            }}
            className="bodyComponentChildBackdrop"
          >
            <FadeInWhenVisible>
              <motion.div
                style={{
                  fontWeight: "800",
                  letterSpacing: "1px",
                }}
                className="heading"
              >
                Virtual Labs.
              </motion.div>
            </FadeInWhenVisible>
            <Divider
              style={{ width: "90%", height: "5px", margin: "20px 5%" }}
            />
            <div style={{ width: "200%", marginLeft: "-100%" }}>
              <TimeLine />
            </div>
            <FadeInWhenVisible>
              <motion.div
                style={{
                  letterSpacing: "1px",
                  textAlign: "justify",
                }}
                className="description"
              >
                VLabs will strive to be something unlike anything ever seen in
                the education software sector. With the possibility for hundreds
                of experiments and revolutionizing edutainment we wish VLabs to
                be something truly different. Every experiment will be taught
                through a theme based step by step approach to ensure full
                understanding of the delicate and complex subject matters.
                Furthermore, We will foster a competitive environment which will
                reward students for their creative thought processes and problem
                solving capabilities.
                <br />
                <br />
                The system will not be one which only helps students learn but
                one that will totally change the way a kid’s skill is graded.
                <br />
                <br />
              </motion.div>
            </FadeInWhenVisible>
            {showReadMore && (
              <FadeInWhenVisible>
                <motion.div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => {
                    history.push("/page/virtuallabs");
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    className="read-more-button"
                  >
                    Read More
                  </Button>
                </motion.div>
              </FadeInWhenVisible>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VLabsComponent;
