import React, { useEffect } from "react";
import solution from "../../images/solution.svg";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

function FadeInWhenVisible({ children }) {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 1 }}
      variants={{
        visible: { x: 0, opacity: 1 },
        hidden: { x: -100, opacity: 0 },
      }}
    >
      {children}
    </motion.div>
  );
}

const YourSolutionComponent = () => {
  return (
    <div className="yoursolution">
      <div
        style={{
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
        className="bodyComponentBiggerChild"
      >
        <div
          style={{
            background: "#14141444",
            borderRadius: "40px",
            margin: "30px",
            // width: "80%",
          }}
          className="bodyComponentChildBackdrop"
        >
          <FadeInWhenVisible>
            <motion.div
              style={{
                fontWeight: "800",
                letterSpacing: "1px",
              }}
              className="heading"
            >
              Our Problem.
            </motion.div>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <motion.div className="body-content">
              There has always been a gap between the theoretical and practical
              application of science studies in schools.
            </motion.div>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <motion.div className="body-content">
              Organizations tried to fill that gap by relying heavily on virtual
              applications, but the practical part is still limited to in-school
              laboratories.
            </motion.div>
          </FadeInWhenVisible>
          <br/>
          <br/>
          <br/>
          <FadeInWhenVisible>
            <motion.div
              style={{
                fontWeight: "800",
                letterSpacing: "1px",
              }}
              className="heading"
            >
              Your Solution.
            </motion.div>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <motion.div className="body-content">
              The Science Explorer Kit fills that gap by pairing a virtual
              platform with a portable hands-on kit.
            </motion.div>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <motion.div className="body-content">
              Students will use the kit and the LabsForHome Virtual Learning
              Platform to perform experiments and complete projects across
              multiple disciplines, while working at a pace that suits their
              learning style.
            </motion.div>
          </FadeInWhenVisible>
        </div>
      </div>
      <FadeInWhenVisible>
        <div
          className="yoursolutionbody"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={solution} alt="" />
        </div>
      </FadeInWhenVisible>
    </div>
  );
};

export default YourSolutionComponent;
