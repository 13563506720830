import React from "react";
import { useRef } from "react";
import { motion } from "framer-motion";
import { MenuToggle } from "./MenuToggle";
import { Navigation } from "./Navigation";

const NavbarButton = ({ isOpen, toggleOpen }) => {
  const containerRef = useRef(null);
  // Navbar button open and close animation
  return (
    <motion.nav
      className="navbar-button"
      initial={false}
      animate={isOpen ? "open" : "closed"}
      ref={containerRef}
      style={{ height: "80px" }}
    >
      <motion.div
        className="background"
        
        variants={{
          open: {
            clipPath: `circle(140% at calc(100% - 40px) 40px)`,
            transition: {
              type: "spring",
              stiffness: 30,
              restDelta: 2,
            },
          },
          closed: {
            clipPath: `circle(30px at calc(100% - 60px) 40px)`,
            transition: {
              delay: 0.5,
              type: "spring",
              stiffness: 200,
              damping: 15,
            },
          },
        }}
      />
      <Navigation isOpen={isOpen} toggleOpen={toggleOpen} />
      <MenuToggle toggle={() => toggleOpen()} />
    </motion.nav>
  );
};

export { NavbarButton };
