import labs_for_home_new from "../../images/labs_for_home_new.png";
import logo from "../../images/Logo.png";
import Typed from "react-typed";
import { FaFlask, FaMicroscope, FaAtom } from "react-icons/fa";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

function FadeInWhenVisible({ children }) {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 1 }}
      variants={{
        visible: { opacity: 1, scale: 1 },
        hidden: { opacity: 0, scale: 0 },
      }}
      style={{ cursor: "pointer" }}
    >
      {children}
    </motion.div>
  );
}

const textLines = [`Physics`, "Chemistry", "Biology"];

const LandingPage = ({ isOpen }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const [transition, setTransition] = React.useState(false);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
      if (transition) setTransition(false);
    } else {
      setTransition(true);
    }
  }, [controls, inView, transition]);

  useEffect(() => {
    setTransition(false);
  }, []);

  const history = useHistory();

  return (
    <motion.div
      className="landing-page"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "inherit",
      }}
    >
      <div
        className="landing-page-image"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <motion.img
          className="arduino-img"
          animate={controls}
          variants={{
            visible: { x: 0, y: 0, scale: [0.9, 1], opacity: 1 },
            hidden: { opacity: 0, scale: 0 },
          }}
          transition={{ duration: 1 }}
          src={logo}
          alt="Arduino"
          style={{
            width: "80%",
          }}
        />
        <motion.img
          className="arduino-img"
          animate={controls}
          variants={{
            visible: { x: 0, y: 0, scale: [0.9, 1], opacity: 1 },
            hidden: { opacity: 0, scale: 0 },
          }}
          transition={{ duration: 1 }}
          src={labs_for_home_new}
          alt="Arduino"
          style={{
            width: "80%",
          }}
        />
      </div>
      <div
        className="landing-page-content"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "50px 0",
        }}
      >
        <center
          style={{
            background: "#14141444",
            borderRadius: "40px",
            width: "70%",
            padding: "30px",
          }}
          className="bodyComponentChildBackdrop"
        >
          <FadeInWhenVisible>
            <motion.h1
              initial={{ x: "100vw" }}
              animate={{ x: 0, y: 0, scale: [1.2, 1] }}
              transition={{ duration: 0.8 }}
              style={{ color: "white" }}
              className="heading"
            >
              Bring the Lab to your Home!
            </motion.h1>
          </FadeInWhenVisible>
          <br />
          <motion.div
            animate={{ opacity: [0, 1] }}
            transition={{ duration: 1.2 }}
            style={{ color: "white" }}
          >
            <div className="typed" style={{ color: "cyan" }} ref={ref}>
              <Typed
                strings={textLines}
                typeSpeed={60}
                backSpeed={60}
                loop={true}
              />
            </div>
          </motion.div>
          <br />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <FadeInWhenVisible>
              <motion.div
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.95 }}
                className="subject-icon"
                onClick={() => {
                  history.push("/page/explorerkit/Chemistry");
                }}
              >
                <FaFlask />
              </motion.div>
            </FadeInWhenVisible>
            <FadeInWhenVisible>
              <motion.div
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.95 }}
                className="subject-icon"
                onClick={() => {
                  history.push("/page/explorerkit/Biology");
                }}
              >
                <FaMicroscope />
              </motion.div>
            </FadeInWhenVisible>
            <FadeInWhenVisible>
              <motion.div
                whileHover={{ scale: 1.3 }}
                whileTap={{ scale: 0.85 }}
                className="subject-icon"
                onClick={() => {
                  history.push("/page/explorerkit/Physics");
                }}
              >
                <FaAtom />
              </motion.div>
            </FadeInWhenVisible>
          </div>
          {!transition ? (
            // <FadeInWhenVisible>
            <motion.div
              style={{
                width: "250px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => {
                history.push("/page/contactus");
              }}
              layoutId="contactus-button"
            >
              <Button
                color="secondary"
                variant="contained"
                className="read-more-button-cyan"
              >
                Contact Us
              </Button>
            </motion.div>
          ) : (
            <div style={{ width: "250px", height: "105px" }}></div>
          )}
        </center>
        {transition && (
          <motion.div
            whileHover={{ scale: 1.3 }}
            whileTap={{ scale: 0.85 }}
            style={{ position: "fixed", bottom: 0, right: 0 }}
            onClick={() => {
              history.push("/page/contactus");
            }}
            layoutId="contactus-button"
          >
            <MailOutlineIcon fontSize="large" className="subject-icon" />
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

export default LandingPage;
