import middle from "../../images/middle.jpeg";
import elementary from "../../images/elementary.jpg";
import why from "../../images/future.jpg";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { Divider } from "@material-ui/core";
import { useCycle } from "framer-motion";
import { animateScroll as scroll } from "react-scroll";
import { scroller } from "react-scroll";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import SEO from "@americanexpress/react-seo";

function FadeInWhenVisible({ children }) { // This component is used to animate the text/images in the future plans section
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: { x: 0, opacity: 1 },
        hidden: { x: "100px", opacity: 0 },
      }}
    >
      {children}
    </motion.div>
  );
}

const FuturePlansComponent = ({ match }) => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  useEffect(() => {
    if (match.params.section !== undefined) {
      document.title = `Future Plans - ${match.params.section}`;
      scroller.scrollTo(match.params.section, {
        duration: 1000,
        spy: true,
        smooth: true,
        offset: -80,
      });
    } else {
      scroll.scrollToTop();
    }
  }, [match]);

  return (
    <>
      {match.params.section === "middle-school" && (
        <SEO
          title="Middle-school Kit"
          description="Teaching the scientific method to students is teaching them how to think, learn, solve problems and make informed decisions. These skills are integral to every aspect of a student’s education and life, from school to career.

        We think that each and every kid should pick up these skills in a way which is fun and engaging further deepening their relationship with the sciences. These skills will help students as they navigate through both the real world and the many academic realms.
        
        Save this space for something big coming soon with our Middle-school Kit!
        "
          keywords={[
            "labs for home",
            "labs",
            "science",
            "education",
            "science explorer kit",
            "kits",
            "virtual labs",
            "experiments",
            "affordable",
            "physics",
            "chemistry",
            "biology",
            "kids learning",
            "STEM",
            "STEM Education",
            "Elementary Kit",
            "science kit for kids",
            "middle school kids",
          ]}
          siteUrl="https://labsforhome.com/page/futureplans/middle-school"
          image={{
            src: "https://labsforhome.com/images/Logo.png",
          }}
        />
      )}
      {match.params.section === "elementary" && (
        <SEO
          title="Elementary Kit"
          description="It is crucial to foster the love of science from a young age! We believe our elementary kit will provide kids the tools necessary to grow their love of science and get a good grasp of the scientific concepts that govern their world.

        Science education activities provide children with opportunities to develop and practice many different skills and attributes. These include communication skills, collaborative skills, team working and perseverance, as well as analytical, reasoning and problem-solving skills.
        
        We will be revealing our elementary kit in the near future so keep those eyes peeled!"
          keywords={[
            "labs for home",
            "labs",
            "science",
            "education",
            "science explorer kit",
            "kits",
            "virtual labs",
            "experiments",
            "affordable",
            "physics",
            "chemistry",
            "biology",
            "kids learning",
            "STEM",
            "STEM Education",
            "Elementary Kit",
            "science kit for kids",
            "middle school kids",
          ]}
          siteUrl="https://labsforhome.com/page/futureplans/elementary"
          image={{
            src: "https://labsforhome.com/images/Logo.png",
          }}
        />
      )}
      {match.params.section === undefined && (
        <SEO
          title="Future Plans"
          description="Learn more about what we plan to bring to the table! Our current offerings focus on high school students however we are making a consistent effort and commitment to changing the way science is taught to both kids and middleschoolers aswell. Please read more to see our plans for the future!"
          keywords={[
            "labs for home",
            "labs",
            "science",
            "education",
            "science explorer kit",
            "kits",
            "virtual labs",
            "experiments",
            "affordable",
            "physics",
            "chemistry",
            "biology",
            "kids learning",
            "STEM",
            "STEM Education",
            "Elementary Kit",
            "science kit for kids",
            "middle school kids",
          ]}
          siteUrl="https://labsforhome.com/page/futureplans"
          image={{
            src: "https://labsforhome.com/images/Logo.png",
          }}
        />
      )}
      <div
        style={{
          height: "auto",
          width: "100vw",
          display: "flex",
        }}
        className="bodyComponentReverse"
      >
        <div
          style={{
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="bodyComponentBiggerChild"
        >
          <div
            style={{
              background: "#14141452",
              borderRadius: "40px",
              margin: "80px 30px",
            }}
            className="bodyComponentChildBackdrop"
          >
            <FadeInWhenVisible>
              <motion.div
                style={{
                  fontWeight: "800",
                  letterSpacing: "1px",
                }}
                className="heading"
              >
                Future Plans
              </motion.div>
            </FadeInWhenVisible>
            <Divider
              style={{ width: "90%", height: "5px", margin: "20px 5%" }}
            />
            <FadeInWhenVisible>
              <motion.div
                style={{
                  letterSpacing: "1px",
                  textAlign: "justify",
                }}
                className="description"
              >
                Learn more about what we plan to bring to the table! Our current
                offerings focus on high school students however we are making a
                consistent effort and commitment to changing the way science is
                taught to both kids and middleschoolers aswell. Please read more
                to see our plans for the future!
                <br />
                <br />
              </motion.div>
            </FadeInWhenVisible>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="bodyComponentSmallerChild"
        >
          <center className="aboutusimage">
            <FadeInWhenVisible>
              <img src={why} alt="svg" className="bodyComponentImage" />
            </FadeInWhenVisible>
          </center>
        </div>
      </div>
      <div className="Our vision">
        <div
          style={{
            height: "auto",
            width: "100vw",
            display: "flex",
          }}
          className="landing-page"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="bodyComponentSmallerChild"
          >
            <center className="aboutusimage">
              <FadeInWhenVisible>
                <img
                  src={elementary}
                  alt="svg"
                  className="bodyComponentImage"
                />
              </FadeInWhenVisible>
            </center>
          </div>

          <div
            style={{
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="bodyComponentBiggerChild"
          >
            {" "}
            <div className="elementary">
              <div
                style={{
                  background: "#14141452",
                  borderRadius: "40px",
                  margin: "80px 30px",
                }}
                className="bodyComponentChildBackdrop"
              >
                <FadeInWhenVisible>
                  <motion.div
                    style={{
                      fontWeight: "800",
                      letterSpacing: "1px",
                    }}
                    className="heading"
                  >
                    Elementary Kit
                  </motion.div>
                </FadeInWhenVisible>
                <Divider
                  style={{ width: "90%", height: "5px", margin: "20px 5%" }}
                />
                <FadeInWhenVisible>
                  <motion.div
                    style={{
                      letterSpacing: "1px",
                      textAlign: "justify",
                    }}
                    className="description"
                  >
                    It is crucial to foster the love of science from a young
                    age! We believe our elementary kit will provide kids the
                    tools necessary to grow their love of science and get a good
                    grasp of the scientific concepts that govern their world.
                    <br />
                    <br />
                    Science education activities provide children with
                    opportunities to develop and practice many different skills
                    and attributes. These include communication skills,
                    collaborative skills, team working and perseverance, as well
                    as analytical, reasoning and problem-solving skills.
                    <br />
                    <br />
                    We will be revealing our elementary kit in the near future
                    so keep those eyes peeled!
                    <br />
                    <br />
                  </motion.div>
                </FadeInWhenVisible>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="middle-school">
        <div
          style={{
            height: "auto",
            width: "100vw",
            display: "flex",
          }}
          className="bodyComponentReverse"
        >
          <div
            style={{
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="bodyComponentBiggerChild"
          >
            <div
              style={{
                background: "#14141452",
                borderRadius: "40px",
                margin: "80px 30px",
              }}
              className="bodyComponentChildBackdrop"
            >
              <FadeInWhenVisible>
                <motion.div
                  style={{
                    fontWeight: "800",
                    letterSpacing: "1px",
                  }}
                  className="heading"
                >
                  Middle-school Kit
                </motion.div>
              </FadeInWhenVisible>
              <Divider
                style={{ width: "90%", height: "5px", margin: "20px 5%" }}
              />
              <FadeInWhenVisible>
                <motion.div
                  style={{
                    letterSpacing: "1px",
                    textAlign: "justify",
                  }}
                  className="description"
                >
                  Teaching the scientific method to students is teaching them
                  how to think, learn, solve problems and make informed
                  decisions. These skills are integral to every aspect of a
                  student’s education and life, from school to career.
                  <br />
                  <br />
                  We think that each and every kid should pick up these skills
                  in a way which is fun and engaging further deepening their
                  relationship with the sciences. These skills will help
                  students as they navigate through both the real world and the
                  many academic realms.
                  <br />
                  <br />
                  Save this space for something big coming soon with our
                  Middle-school Kit!
                  <br />
                  <br />
                </motion.div>
              </FadeInWhenVisible>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="bodyComponentSmallerChild"
          >
            <center className="aboutusimage">
              <FadeInWhenVisible>
                <img src={middle} alt="svg" className="bodyComponentImage" />
              </FadeInWhenVisible>
            </center>
          </div>
        </div>
      </div>
      <Footer />
      <Navbar isOpen={isOpen} toggleOpen={toggleOpen} />
    </>
  );
};

export default FuturePlansComponent;
