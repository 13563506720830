import React, { useState, useCallback } from "react";
import { motion } from "framer-motion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useHistory } from "react-router-dom";

const variants = { // Variables for the animation
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const colors = [ // Colors for the navbar titles
  "#FF008C",
  "#D309E1",
  "#B309E1",
  "#9C1AFF",
  "#881AFF",
  "#5500FF",
  "#3300FF",
];
const titles = [ // Titles for the navbar
  "Home",
  "Grades",
  "Specialisations",
  "Solutions",
  "Future Plans",
  "About Us",
  "Contact Us",
];

const links = [ // Links for the navbar
  "/",
  "Grades",
  "/",
  "Solutions",
  "/page/futureplans",
  "/page/aboutus",
  "/page/contactus",
];

export const MenuItem = ({ i, isOpen, toggleOpen }) => {
  const history = useHistory();
  const [show, setShow] = React.useState(false); // State for the drop down menu for the navbar (grades)
  const [showOne, setShowOne] = React.useState(false); // State for the drop down menu for the navbar (Specialisations)
  const [showTwo, setShowTwo] = React.useState(false); // State for the drop down menu for the navbar (Solutions)
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [hovering, setHovering] = useState(false);

  return (
    <motion.li
      variants={variants}
      className="navbar-li"
      style={{
        cursor: `${isOpen ? "pointer" : "default"}`,
        flexDirection: "column",
        width: "100vw",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <motion.h1
          style={{
            color: colors[i],
            // color: '#ddd',
            fontSize: "2em",
            // marginLeft: `${i === 1 ? `calc(2.5em - 50px)` : "0"}`,
            textTransform: "uppercase",
            letterSpacing: "5px",
          }}
          className="navbar-title"
          whileHover={{
            scale: 1.2,
            zIndex: 1,
          }}
          whileTap={{ scale: 0.95 }}
          onHoverStart={() => {
            if (i === 2) setHovering(true); // To hide the arrow when hovering ( to avoid overlapping )
          }}
          onHoverEnd={() => {
            if (i === 2) setHovering(false);
          }}
          onClick={() => {
            if (i === 1) {
              setShow(!show);
              forceUpdate();
            } else if (i === 3) {
              setShowTwo(!showTwo);
              forceUpdate();
            } else if (i === 2) {
              history.push(links[i]);
              localStorage.setItem("showSek", "true");
              window.location.reload();
              toggleOpen();
            } else {
              history.push(links[i]);
              toggleOpen();
            }
          }}
        >
          {titles[i]}
        </motion.h1>
        {i === 1 && (
          <>
            {show === false ? (
              <motion.div
                initial={{ transform: "rotate(180deg)" }}
                animate={{ transform: "rotate(0deg)", marginLeft: "-20px" }}
              >
                &nbsp; &nbsp; &nbsp; &nbsp;
                <ExpandMoreIcon
                  style={{
                    fontSize: "2.5em",
                    color: "white",
                  }}
                  onClick={() => {
                    setShow(!show);
                    forceUpdate();
                  }}
                />
              </motion.div>
            ) : (
              <motion.div
                initial={{ transform: "rotate(0deg)" }}
                animate={{ transform: "rotate(180deg)" }}
              >
                <ExpandMoreIcon
                  style={{
                    fontSize: "2.5em",
                    color: "white",
                  }}
                  onClick={() => {
                    setShow(!show);
                    forceUpdate();
                  }}
                />
              </motion.div>
            )}
          </>
        )}
        {i === 2 && !hovering && (
          <>
            {showOne === false ? (
              <motion.div
                initial={{ transform: "rotate(180deg)" }}
                animate={{ transform: "rotate(0deg)", marginLeft: "-20px" }}
              >
                &nbsp; &nbsp; &nbsp; &nbsp;
                <ExpandMoreIcon
                  style={{
                    fontSize: "2.5em",
                    color: "white",
                  }}
                  onClick={() => {
                    setShowOne(!showOne);
                    forceUpdate();
                  }}
                />
              </motion.div>
            ) : (
              <motion.div
                initial={{ transform: "rotate(0deg)" }}
                animate={{ transform: "rotate(180deg)" }}
              >
                <ExpandMoreIcon
                  style={{
                    fontSize: "2.5em",
                    marginLeft: "25px",
                    color: "white",
                  }}
                  onClick={() => {
                    setShowOne(!showOne);
                    forceUpdate();
                  }}
                />
              </motion.div>
            )}
          </>
        )}
        {i === 3 && (
          <>
            {showTwo === false ? (
              <motion.div
                initial={{ transform: "rotate(180deg)" }}
                animate={{ transform: "rotate(0deg)", marginLeft: "-20px" }}
              >
                &nbsp; &nbsp; &nbsp; &nbsp;
                <ExpandMoreIcon
                  style={{
                    fontSize: "2.5em",
                    color: "white",
                  }}
                  onClick={() => {
                    setShowTwo(!showTwo);
                    forceUpdate();
                  }}
                />
              </motion.div>
            ) : (
              <motion.div
                initial={{ transform: "rotate(0deg)" }}
                animate={{ transform: "rotate(180deg)" }}
              >
                <ExpandMoreIcon
                  style={{
                    fontSize: "2.5em",
                    color: "white",
                  }}
                  onClick={() => {
                    setShowTwo(!showTwo);
                    forceUpdate();
                  }}
                />
              </motion.div>
            )}
          </>
        )}
      </div>
      {i === 1 && show === true && (
        <motion.h3
          style={{
            display: "flex",
          }}
          className="sub-title"
          animate={{ scale: [0, 1.1, 1] }}
        >
          <motion.p
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              history.push("/page/explorerkit");
              toggleOpen();
            }}
          >
            High-School
          </motion.p>
          <motion.p
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              localStorage.setItem("showSek", "true");
              localStorage.setItem("showFive", "true");
              history.push("/ib/Physics/sections");
              window.location.reload();
              toggleOpen();
            }}
          >
            Middle-School
          </motion.p>
          <motion.p
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              localStorage.setItem("showSek", "true");
              localStorage.setItem("showOne", "true");
              history.push("/ib/Physics/sections");
              window.location.reload();
              toggleOpen();
            }}
          >
            Elementary
          </motion.p>
        </motion.h3>
      )}
      {i === 2 && showOne === true && (
        <motion.h3
          style={{
            display: "flex",
            // flexDirection: "row",
          }}
          className="sub-title"
          animate={{ scale: [0, 1.1, 1] }}
        >
          <motion.p
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              history.push("/page/explorerkit/Physics");
              toggleOpen();
            }}
          >
            Physics
          </motion.p>
          <motion.p
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              history.push("/page/explorerkit/Chemistry");
              toggleOpen();
            }}
          >
          Chemistry
          </motion.p>
          <motion.p
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              history.push("/page/explorerkit/Biology");
              toggleOpen();
            }}
          >
          Biology
          </motion.p>
        </motion.h3>
      )}
      {i === 3 && showTwo === true && (
        <motion.h3
          style={{
            display: "flex",
          }}
          className="sub-title"
          animate={{ scale: [0, 1.1, 1] }}
        >
          <motion.p
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              history.push("/page/explorerkit");
              toggleOpen();
            }}
          >
            Science Explorer Kit
          </motion.p>
          <motion.p
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              history.push("/page/virtuallabs");
              toggleOpen();
            }}
          >
            Virtual Labs
          </motion.p>
        </motion.h3>
      )}
    </motion.li>
  );
};
