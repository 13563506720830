import "./App.css";
import React, { useEffect } from "react";
import Navbar from "./Components/Navbar/Navbar";
import LandingPage from "./Components/LandingPage/LandingPage";
import { useCycle } from "framer-motion";
import ScienceExplorerKitComponent from "./Components/ScienceExplorerKitComponent/ScienceExplorerKitComponent";
import VLabsComponent from "./Components/VLabsComponent/VLabsComponent";
import { AnimateSharedLayout } from "framer-motion";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import YourSolutionComponent from "./Components/YourSolutionComponent/YourSolutionComponent";
import Footer from "./Components/Footer/Footer";
import InteractiveBlock from "./Components/InteractiveBlock/InteractiveBlock";
import { animateScroll as scroll } from "react-scroll";
import { scroller } from "react-scroll";
import ContactUs from "./Components/ContactUs/ContactUs";
import AboutUsComponent from "./Components/AboutUs/AboutUsComponent";
import FuturePlansComponent from "./Components/FuturePlans/FuturePlansComponent";
import FourZeroFourPage from "./Components/FourZeroFourPage/FourZeroFourPage";
import SEO from "@americanexpress/react-seo";
import Admin from "./Components/Admin/Admin";
// import SlideshowComponent from "./Components/SlideshowComponent/SlideshowComponent";

const App = () => {
  return (
    <div className="container">
      <AnimateSharedLayout type="crossfade">
        <Router>
          <Switch>
          <Route
              path={'/admin'}
              exact={true}
              component={Admin}
            />
            <Route
              path={[
                "/card/:id",
                "/card/:id/:section",
                "/ib/:id/:section",
                "/",
              ]}
              exact={true}
              component={Main}
            />
            <Route
              path={"/page/virtuallabs"}
              exact={true}
              component={VirtualLabs}
            />
            <Route
              path={["/page/explorerkit", "/page/explorerkit/:subject"]}
              exact={true}
              component={ScienceExplorerKitPage}
            />
            <Route
              path={["/page/futureplans", "/page/futureplans/:section"]}
              exact={true}
              component={FuturePlansComponent}
            />
            <Route
              path={"/page/contactus"}
              exact={true}
              component={ContactUs}
            />
            <Route path={"/page/aboutus"} exact={true} component={AboutUs} />
            <Route component={FourZeroFourPage} />
          </Switch>
        </Router>
      </AnimateSharedLayout>
    </div>
  );
};

const Main = ({ match }) => {
  const physics = [
    "Ohm's Law proof",
    "Measuring Voltage Drop for a set of resistors (Series - parallel - Series & parallel)",
    "Measuring Electrical Current for a set of resistors (Series - parallel - Series & parallel)",
    "Measuring the resistance (Series - parallel - Series & parallel)",
    "Light (color Dependent reflectivity)",
    "Measuring the Distance between two points",
    "Measuring the Speed of an object at a set of distances",
    "Measuring the free fall between two points",
    "Measuring Weight and mass using the load cell",
    "Measuring the friction force",
    "Capacitors in direct current circuits",
    "Diodes as rectifiers",
    "Newton's First Law",
    "Relationship between a wire cross-sectional area and the wire's resistance",
    "Relationship between a wire length and the wire's resistance",
    "The magnetic effect of a current-carrying conductor",
    "Relationship between speed and acceleration",
  ];
  const chemistry = [
    "Specific heat of fluids",
    "Measuring Electrical conductivity for a solution",
    "Definition of bond type of a solution (Covalent or Ionic)",
    "Measuring of Dissolving substance",
    "PH Measuring",
    "Neutralization / Titration reaction",
    "Electrical conductivity of solutions",
    "Heat Balance Experiment",
    "Dissolving Heat Experiment",
    "Examine water sample from different sources",
    "Determine Specific heat of distilled water",
    "Compare PH of distilled water, tape water and mineral water",
  ];
  const biology = [
    "Measuring ambient temperature and Humidity",
    "Monitoring the CO2 gas concentration of a plant locked in a vessel",
    "Heart Rate",
    "Monitoring light intensity and its effect on photosynthesis",
    "Monitoring the Oxygen gas concentration in of a plant locked in a vessel",
    "Relation between Height and Humidity",
    "Relation between Height and temprature",
    "Heart rate in Rest",
    "Heart rate in Exertion",
  ];
  const [isOpen, toggleOpen] = useCycle(false, true);
  useEffect(() => {
    scroll.scrollToTop();
  }, []);
  return (
    <div>
      <SEO
        title="Labs For Home"
        description="LabsForHome was born out of a love for science and a intense drive to give students the education they need, nay – deserve. We have poured all of our combined experience, research and love into creating a perfect solution for the current difficult times we find ourselves in.
        We found that there was no real solution to the hands on experience that students were tragically missing out on and decided to start this project. Our mission is to ensure that every student has the tools needed to have a fulfilling science education – fostering their love for learning. Our Problem.
        There has always been a gap between the theoretical and practical application of science studies in schools.
        Organizations tried to fill that gap by relying heavily on virtual applications, but the practical part is still limited to in-school laboratories.
        Your Solution.
        The Science Explorer Kit fills that gap by pairing a virtual platform with a portable hands-on kit.
        Students will use the kit and the LabsForHome Virtual Learning Platform to perform experiments and complete projects across multiple disciplines, while working at a pace that suits their learning style."
        keywords={[
          "labs for home",
          "labs",
          "science",
          "education",
          "science explorer kit",
          "kits",
          "virtual labs",
          "experiments",
          "affordable",
          "physics",
          "chemistry",
          "biology",
          "kids learning",
          "STEM",
          "STEM Education",
          'high school',
          'middle school',
          'elementary',
          'school',
          ...biology,
          ...physics,
          ...chemistry,
        ]}
        siteUrl="https://labsforhome.com"
        image={{
          src: "https://labsforhome.com/images/Logo.png",
        }}
      />
      <InteractiveBlock match={match} />
      <LandingPage isOpen={isOpen} toggleOpen={toggleOpen} />
      <ScienceExplorerKitComponent showReadMore={true} />
      <VLabsComponent showReadMore={true} />
      <YourSolutionComponent />
      {/* <SlideshowComponent /> // uncomment this to show the slideshow*/}
      <Footer />
      <Navbar isOpen={isOpen} toggleOpen={toggleOpen} />
    </div>
  );
};

const VirtualLabs = ({ match }) => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  useEffect(() => {
    scroll.scrollToTop();
  }, []);
  return (
    <div>
      <SEO
        title="Virtual Labs"
        description="VLabs will strive to be something unlike anything ever seen in the education software sector. With the possibility for hundreds of experiments and revolutionizing edutainment we wish VLabs to be something truly different. Every experiment will be taught through a theme based step by step approach to ensure full understanding of the delicate and complex subject matters. Furthermore, We will foster a competitive environment which will reward students for their creative thought processes and problem solving capabilities.

        The system will not be one which only helps students learn but one that will totally change the way a kid’s skill is graded."
        keywords={[
          "labs for home",
          "labs",
          "science",
          "education",
          "science explorer kit",
          "kits",
          "virtual labs",
          "experiments",
          "affordable",
          "physics",
          "chemistry",
          "biology",
          "kids learning",
          "50+ Experiments",
          "Engaging competition based learning",
          "Theme based approach",
          "Affordable",
          "Step by step approach",
        ]}
        siteUrl="https://labsforhome.com/page/virtuallabs"
        image={{
          src: "https://labsforhome.com/images/Logo.png",
        }}
      />
      <VLabsComponent showReadMore={false} />
      <Footer />
      <Navbar isOpen={isOpen} toggleOpen={toggleOpen} />
    </div>
  );
};

const ScienceExplorerKitPage = ({ match }) => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const [seo, setSeo] = React.useState(<></>);
  useEffect(() => {
    const physics = [
      "Ohm's Law proof",
      "Measuring Voltage Drop for a set of resistors (Series - parallel - Series & parallel)",
      "Measuring Electrical Current for a set of resistors (Series - parallel - Series & parallel)",
      "Measuring the resistance (Series - parallel - Series & parallel)",
      "Light (color Dependent reflectivity)",
      "Measuring the Distance between two points",
      "Measuring the Speed of an object at a set of distances",
      "Measuring the free fall between two points",
      "Measuring Weight and mass using the load cell",
      "Measuring the friction force",
      "Capacitors in direct current circuits",
      "Diodes as rectifiers",
      "Newton's First Law",
      "Relationship between a wire cross-sectional area and the wire's resistance",
      "Relationship between a wire length and the wire's resistance",
      "The magnetic effect of a current-carrying conductor",
      "Relationship between speed and acceleration",
    ];
    const chemistry = [
      "Specific heat of fluids",
      "Measuring Electrical conductivity for a solution",
      "Definition of bond type of a solution (Covalent or Ionic)",
      "Measuring of Dissolving substance",
      "PH Measuring",
      "Neutralization / Titration reaction",
      "Electrical conductivity of solutions",
      "Heat Balance Experiment",
      "Dissolving Heat Experiment",
      "Examine water sample from different sources",
      "Determine Specific heat of distilled water",
      "Compare PH of distilled water, tape water and mineral water",
    ];
    const biology = [
      "Measuring ambient temperature and Humidity",
      "Monitoring the CO2 gas concentration of a plant locked in a vessel",
      "Heart Rate",
      "Monitoring light intensity and its effect on photosynthesis",
      "Monitoring the Oxygen gas concentration in of a plant locked in a vessel",
      "Relation between Height and Humidity",
      "Relation between Height and temprature",
      "Heart rate in Rest",
      "Heart rate in Exertion",
    ];
    if (match.params.subject !== undefined) {
      document.title = `Science Explorer Kit - ${match.params.subject}`;
      if (match.params.subject === "Physics") {
        setSeo(
          <SEO
            title="Physics"
            description="The branch of science concerned with the nature and properties of matter and energy! Physics helps us understand the natural laws which govern our world and dictate the behaviour of all physical objects.

        Through the use of our revolutionary kit we breach the subject matter of heat, light, sound, electricity and others making them fully accessible to students.
        "
            keywords={physics}
            siteUrl="https://labsforhome.com/page/explorerkit/Physics"
            image={{
              src: "https://labsforhome.com/images/Logo.png",
            }}
          />
        );
      }
      if (match.params.subject === "Chemistry") {
        setSeo(
          <SEO
            title="Chemistry"
            description="When you think Chemistry, you think labs! It is the study of matter, defined as anything that has mass and takes up space, and the changes that matter can undergo when it is subject to different environments and conditions.

        Chemistry seeks to understand not only the properties of matter, like the mass or composition of a chemical element, but also how and why matter undergoes certain changes."
            keywords={chemistry}
            siteUrl="https://labsforhome.com/page/explorerkit/Chemistry"
            image={{
              src: "https://labsforhome.com/images/Logo.png",
            }}
          />
        );
      }
      if (match.params.subject === "Biology") {
        setSeo(
          <SEO
            title="Biology"
            description="Biology is the scientific study of life - the study of living organisms, divided into many specialized fields that cover their morphology, physiology, anatomy, behaviour, origin, and distribution.
  
          Through the use of our kit we help students get more in touch with the behavior of plants, temperature and others."
            keywords={biology}
            siteUrl="https://labsforhome.com/page/explorerkit/Biology"
            image={{
              src: "https://labsforhome.com/images/Logo.png",
            }}
          />
        );
      }
      scroller.scrollTo(match.params.subject, {
        duration: 1000,
        spy: true,
        smooth: true,
        offset: -80,
      });
    } else {
      document.title = "Science Explorer Kit";
      setSeo(
        <SEO
          title="Science Explorer Kit"
          description="The Science Explorer Kit is a tool of infinite possibilities built with passion and care. With over 45 components and 30 experiments the uses are endless. We have built and curated each project and experiment with learning in mind to really recreate the experiences a student could have in a real lab.

        We believe that our kit is the new staple of education in our modern circumstances and will come to set new expectations for the way science is taught in schools.
        "
          keywords={[
            "labs for home",
            "labs",
            "science",
            "education",
            "science explorer kit",
            "kits",
            "virtual labs",
            "experiments",
            "affordable",
            "physics",
            "chemistry",
            "biology",
            "kids learning",
            "50+ Experiments",
            "Engaging competition based learning",
            "Theme based approach",
            "Affordable",
            "Step by step approach",
            "30+ Experiments",
            "Grades 9 - 12",
            ...physics,
            ...chemistry,
            ...biology,
          ]}
          siteUrl="https://labsforhome.com/page/explorerkit"
          image={{
            src: "https://labsforhome.com/images/Logo.png",
          }}
        />
      );
      scroll.scrollToTop();
    }
  }, [match]);
  return (
    <div>
      {seo}
      <ScienceExplorerKitComponent showReadMore={false} />
      <Footer />
      <Navbar isOpen={isOpen} toggleOpen={toggleOpen} />
    </div>
  );
};

const AboutUs = ({ match }) => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  useEffect(() => {
    scroll.scrollToTop();
  }, []);
  return (
    <div>
      <SEO
        title="About Us"
        description="Labs from Home About Us: LabsForHome was born out of a love for science and a intense drive to give students the education they need, nay – deserve. We have poured all of our combined experience, research and love into creating a perfect solution for the current difficult times we find ourselves in.

        We found that there was no real solution to the hands on experience that students were tragically missing out on and decided to start this project. Our mission is to ensure that every student has the tools needed to have a fulfilling science education – fostering their love for learning.
        
        Our vision for LabsForHome is one of passion and grandeur. We do not see this as a simple kit or a simple software. No – it is something much, much more. We wish to drive different schools locally and internationally to compete with each other, therefore fostering a health competitive environment. We wish to award students badges and digital certificates based on their performances, ushering in a new age of digital-based accreditation. We wish to give all students an equal chance to thrive in the lab when previously they might have been overshadowed or not had the opportunity.

        We wish that you share our vision with us and that we can truly build something magnificent moving forward!
        
        Science Explorer Kit: The science explorer kit is a revolutionary solution to a relevant problem. Schools have been teaching science in the lab for decades - so what happens when schools close and students are stuck at home?

        We believe that Science is taught best when students can see with their own eyes the results of their actions and the best way to show them that is through the lab. Well - if the students can't go to the lab then we'll make the lab go to them!
        
        The kit will come with everything necessary to give a high-schooler an enriching experience of the sciences.
        
        Virtual Labs: Not enough experiments for you? Well – let us introduce VLabs!

        With upwards of 50 experiments in an affordable and convenient package VLabs has everything a student could possibly need to learn the sciences.
        
        Our proprietary software allows students to perfectly replicate the experiments done in a virtual world. Furthermore, the inclusion of a plethora of accessibility features means that any and all students can benefit through the use of VLabs!
        
        VLabs will offer a innovative and revolutionary way of studying science.
        "
        keywords={[
          "labs for home",
          "labs",
          "science",
          "education",
          "science explorer kit",
          "kits",
          "virtual labs",
          "experiments",
          "affordable",
          "physics",
          "chemistry",
          "biology",
          "kids learning",
          "50+ Experiments",
          "Engaging competition based learning",
          "Theme based approach",
          "Affordable",
          "Step by step approach",
          "30+ Experiments",
          "Grades 9 - 12",
        ]}
        siteUrl="https://labsforhome.com/page/aboutus"
        image={{
          src: "https://labsforhome.com/images/Logo.png",
        }}
      />
      <AboutUsComponent />
      <Footer />
      <Navbar isOpen={isOpen} toggleOpen={toggleOpen} />
    </div>
  );
};

export default App;
