import React from "react";
import { items } from "./data";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import LaunchIcon from "@material-ui/icons/Launch";
import { useHistory } from "react-router-dom";

function Card({ id, title, category, theme }) {
  const history = useHistory();
  return (
    <motion.li
      className={`card ${theme}`}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 1 }}
    >
      <motion.div className="card-content-container">
        <motion.div className="card-content" layoutId={`card-container-${id}`}>
          <motion.div className="card-image-container" layoutId={`${title}`}>
            <img className="card-image" src={`images/${id}.gif`} alt="" />
          </motion.div>
          <motion.div
            className="title-container"
            layoutId={`title-container-${id}`}
            whileHover={{ opacity: 1 }}
          >
            <span className="category">{category}</span>
            <h2>{title}</h2>
          </motion.div>
        </motion.div>
      </motion.div>
      <Link to={`/card/${id}`} className={`card-open-link`} />
      {history.location.pathname === "/" && (
        <LaunchIcon
        stroke={"black"} strokeWidth={1}
          style={{
            fontSize: "5em",
            color: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            cursor: "pointer",
          }}
          onClick={() => {
            history.push(`/card/${id}`);
          }}
        />
      )}
    </motion.li>
  );
}

export function List({ selectedId }) {
  return (
    <motion.ul
      className="card-list"
      animate={{ scale: [0.5, 1], opacity: [0.7, 1] }}
      transition={{ duration: 0.5 }}
    >
      {items.map((card) => (
        <Card key={card.id} {...card} isSelected={card.id === selectedId} />
      ))}
    </motion.ul>
  );
}
