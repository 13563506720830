import React from "react";
import { motion } from "framer-motion";
import { Link, useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { items } from "./data";
import { animateScroll as scroll } from "react-scroll";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import p from "../../images/physics_l.gif";
import c from "../../images/chemistry_l.gif";
import b from "../../images/biology_l.gif";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

export function Item({ id }) {
  const { title } = items.find((item) => item.id === id);
  const history = useHistory();
  const CloseButton = () => {
    const history = useHistory();

    return (
      <motion.div
        style={{
          position: "absolute",
          top: "40px",
          right: "40px",
          zIndex: "5",
        }}
        className="subjectcardclose"
        onClick={() => {
          history.push("/");
          document.getElementsByClassName("subjectcardclose")[0].style.display =
            "none";
        }}
        animate={{ scale: [0, 1.8, 0.8, 1.3], rotate: [0, 90, 45, 0] }}
        transition={{ duration: 0.8 }}
      >
        <IconButton
          size="medium"
          style={{ background: "white", border: "5px solid black" }}
        >
          <CloseIcon />
        </IconButton>
      </motion.div>
    );
  };
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.15 } }}
        transition={{ duration: 0.2, delay: 0.15 }}
        className="overlay"
      >
        <Link to="/" />
      </motion.div>
      <div className="card-content-container open">
        <motion.div className="card-content" layoutId={`card-container-${id}`}>
          {/* <Link to="/"> */}
          <motion.div className="card-image-container" layoutId={`${title}`}>
            <motion.img
              className="card-image"
              src={`/images/${id}.gif`}
              alt=""
            />
          </motion.div>
          <motion.div
            style={{
              // width: "400px",
              left: "50%",
              top: "300px",
              translateX: "-50%",
              zIndex: "1",
              position: "absolute",
            }}
            animate={{ rotate: [50, 0, -10, 0], x: [0, -50, -50, 0] }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              history.push(`/ib/${title}/sections`);
              scroll.scrollToTop();
            }}
          >
            <Button
              color="primary"
              variant="contained"
              className="read-more-button"
              style={{ minWidth: "320px" }}
            >
              Show Categories
            </Button>
          </motion.div>
          <CloseButton />

          <div className="scroll-to-read-more">
            <div
              style={{
                color: "white",
                position: "absolute",
                top: "425px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "0",
                flexDirection: "column",
              }}
            >
              <div style={{ color: "white" }}>Scroll down to see more</div>
              <ExpandMoreIcon />
            </div>
          </div>
          {/* </Link> */}
          <motion.div
            className="title-container"
            layoutId={`title-container-${id}`}
          >
            <h2>{title}</h2>
          </motion.div>
          <br />
          {title === "Physics" && (
            <motion.div className="content-container" animate>
              The branch of science concerned with the nature and properties of
              matter and energy! Physics helps us understand the natural laws
              which govern our world and dictate the behaviour of all physical
              objects.
              <br />
              <br />
              Through the use of our revolutionary kit we breach the subject
              matter of heat, light, sound, electricity and others making them
              fully accessible to students.
              <br />
              <br />
              Click the button to find out more about the experiments we offer
              and how we can help make physics education fun!
              <br />
              <br />
              <center>
                <Zoom overlayBgColorEnd="#141414cc">
                  <motion.img
                    whileHover={{ scale: 1.1 }}
                    src={p}
                    style={{
                      width: "100%",
                      borderRadius: "30px",
                      cursor: "pointer",
                    }}
                    alt="physics gif"
                  />
                </Zoom>
              </center>
            </motion.div>
          )}
          {title === "Biology" && (
            <motion.div className="content-container" animate>
              Biology is the scientific study of life - the study of living
              organisms, divided into many specialized fields that cover their
              morphology, physiology, anatomy, behaviour, origin, and
              distribution.
              <br />
              <br />
              Through the use of our kit we help students get more in touch with
              the behavior of plants, temperature and others.
              <br />
              <br />
              Click the button to find out how our kit can help explore the vast
              organic world from the home!
              <br />
              <br />
              <center>
                <Zoom overlayBgColorEnd="#141414cc">
                  <motion.img
                    whileHover={{ scale: 1.1 }}
                    src={b}
                    style={{
                      width: "100%",
                      borderRadius: "30px",
                      cursor: "pointer",
                    }}
                    alt="biology gif"
                  />
                </Zoom>
              </center>
            </motion.div>
          )}
          {title === "Chemistry" && (
            <motion.div className="content-container" animate>
              When you think Chemistry, you think labs! It is the study of
              matter, defined as anything that has mass and takes up space, and
              the changes that matter can undergo when it is subject to
              different environments and conditions.
              <br />
              <br />
              Chemistry seeks to understand not only the properties of matter,
              like the mass or composition of a chemical element, but also how
              and why matter undergoes certain changes.
              <br />
              <br />
              Click the button to see just how we can give you a real lab
              experience all from the home!
              <br />
              <br />
              <center>
                <Zoom overlayBgColorEnd="#141414cc">
                  <motion.img
                    whileHover={{ scale: 1.1 }}
                    src={c}
                    style={{
                      width: "100%",
                      borderRadius: "30px",
                      cursor: "pointer",
                    }}
                    alt="chemistry gif"
                  />
                </Zoom>
              </center>
            </motion.div>
          )}
        </motion.div>
      </div>
    </>
  );
}
