import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";

const variants = { // variants for the animation
  open: {
    transition: { staggerChildren: 0.1, delayChildren: 0.3 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
    top: "-100vh",
  },
};

// the navbar itself

export const Navigation = ({ isOpen, toggleOpen }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setIsAnimating(true);
      setTimeout(() => setIsAnimating(false), 1000);
    } else {
      setIsAnimating(true);
    }
  }, [isOpen]);

  return (
    <div
      style={{
        position: "absolute",
        display: isAnimating ? "block" : "none",
        top: "50vh",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <motion.ul variants={variants}>
        {itemIds.map((i) => (
          <MenuItem i={i} key={i} isOpen={isOpen} toggleOpen={toggleOpen} />
        ))}
      </motion.ul>
    </div>
  );
};

const itemIds = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
