import vlabs from "../../images/vlabs.jpg";
import sek from "../../images/sek.jpeg";
import ourvision from "../../images/our_vision.jpeg";
import why from "../../images/why.jpg";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { Divider } from "@material-ui/core";

function FadeInWhenVisible({ children }) { // Function to determine when an element is in view and if in view, trigger an animation
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: .5 }}
      variants={{
        visible: { x: 0, opacity: 1 },
        hidden: { x: "100px", opacity: 0 },
      }}
    >
      {children}
    </motion.div>
  );
}

const AboutUsComponent = ({ showReadMore }) => {
  // Content for about us page, each element being wrapped in FadeInWhenVisible to trigger animation when in view

  return (
    <>
      <div
        style={{
          height: "auto",
          width: "100vw",
          display: "flex",
        }}
        className="bodyComponentReverse"
      >
        <div
          style={{
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="bodyComponentBiggerChild"
        >
          <div
            style={{
              background: "#14141452",
              borderRadius: "40px",
              margin: "80px 30px",
            }}
            className="bodyComponentChildBackdrop"
          >
            <FadeInWhenVisible>
              <motion.div
                style={{
                  fontWeight: "800",
                  letterSpacing: "1px",
                }}
                className="heading"
              >
                Why did we start LabsForHome?
              </motion.div>
            </FadeInWhenVisible>
            <Divider
              style={{ width: "90%", height: "5px", margin: "20px 5%" }}
            />
            <FadeInWhenVisible>
              <motion.div
                style={{
                  letterSpacing: "1px",
                  textAlign: "justify",
                }}
                className="description"
              >
                LabsForHome was born out of a love for science and a intense
                drive to give students the education they need, nay – deserve.
                We have poured all of our combined experience, research and love
                into creating a perfect solution for the current difficult times
                we find ourselves in.
                <br />
                <br />
                We found that there was no real solution to the hands on
                experience that students were tragically missing out on and
                decided to start this project. Our mission is to ensure that
                every student has the tools needed to have a fulfilling science
                education – fostering their love for learning.
                <br />
                <br />
              </motion.div>
            </FadeInWhenVisible>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="bodyComponentSmallerChild"
        >
          <center className='aboutusimage'>
            <FadeInWhenVisible>
              <img src={why} alt="svg" className="bodyComponentImage" />
            </FadeInWhenVisible>
          </center>
        </div>
      </div>
      <div className="Our vision">
        <div
          style={{
            height: "auto",
            width: "100vw",
            display: "flex",
          }}
          className="landing-page"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="bodyComponentSmallerChild"
          >
            <center className='aboutusimage'>
              <FadeInWhenVisible>
                <img
                  src={ourvision}
                  alt="svg"
                  className="bodyComponentImage"
                />
              </FadeInWhenVisible>
            </center>
          </div>
          <div
            style={{
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="bodyComponentBiggerChild"
          >
            <div
              style={{
                background: "#14141452",
                borderRadius: "40px",
                margin: "80px 30px",
              }}
              className="bodyComponentChildBackdrop"
            >
              <FadeInWhenVisible>
                <motion.div
                  style={{
                    fontWeight: "800",
                    letterSpacing: "1px",
                  }}
                  className="heading"
                >
                  Our vision
                </motion.div>
              </FadeInWhenVisible>
              <Divider
                style={{ width: "90%", height: "5px", margin: "20px 5%" }}
              />
              <FadeInWhenVisible>
                <motion.div
                  style={{
                    letterSpacing: "1px",
                    textAlign: "justify",
                  }}
                  className="description"
                >
                  Our vision for LabsForHome is one of passion and grandeur. We
                  do not see this as a simple kit or a simple software. No – it
                  is something much, much more. We wish to drive different
                  schools locally and internationally to compete with each
                  other, therefore fostering a health competitive environment.
                  We wish to award students badges and digital certificates
                  based on their performances, ushering in a new age of
                  digital-based accreditation. We wish to give all students an
                  equal chance to thrive in the lab when previously they might
                  have been overshadowed or not had the opportunity.
                  <br />
                  <br />
                  We wish that you share our vision with us and that we can
                  truly build something magnificent moving forward!
                  <br />
                  <br />
                </motion.div>
              </FadeInWhenVisible>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          height: "auto",
          width: "100vw",
          display: "flex",
        }}
        className="bodyComponentReverse"
      >
        <div
          style={{
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="bodyComponentBiggerChild"
        >
          <div
            style={{
              background: "#14141452",
              borderRadius: "40px",
              margin: "80px 30px",
            }}
            className="bodyComponentChildBackdrop"
          >
            <FadeInWhenVisible>
              <motion.div
                style={{
                  fontWeight: "800",
                  letterSpacing: "1px",
                }}
                className="heading"
              >
                The Science Explorer Kit
              </motion.div>
            </FadeInWhenVisible>
            <Divider
              style={{ width: "90%", height: "5px", margin: "20px 5%" }}
            />
            <FadeInWhenVisible>
              <motion.div
                style={{
                  letterSpacing: "1px",
                  textAlign: "justify",
                }}
                className="description"
              >
                The science explorer kit is a revolutionary solution to a
                relevant problem. Schools have been teaching science in the lab
                for decades - so what happens when schools close and students
                are stuck at home?
                <br />
                <br />
                We believe that Science is taught best when students can see
                with their own eyes the results of their actions and the best
                way to show them that is through the lab. Well - if the students
                can't go to the lab then we'll make the lab go to them!
                <br />
                <br />
                The kit will come with everything necessary to give a
                high-schooler an enriching experience of the sciences.
                <br />
                <br />
              </motion.div>
            </FadeInWhenVisible>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="bodyComponentSmallerChild"
        >
          <center className='aboutusimage'>
            <FadeInWhenVisible>
              <img src={sek} alt="svg" className="bodyComponentImage" />
            </FadeInWhenVisible>
          </center>
        </div>
      </div>
      <div className="Virtual Labs">
        <div
          style={{
            height: "auto",
            width: "100vw",
            display: "flex",
          }}
          className="landing-page"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="bodyComponentSmallerChild"
          >
            <center className='aboutusimage'>
              <FadeInWhenVisible>
                <img
                  src={vlabs}
                  alt="svg"
                  className="bodyComponentImage"
                />
              </FadeInWhenVisible>
            </center>
          </div>
          <div
            style={{
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="bodyComponentBiggerChild"
          >
            <div
              style={{
                background: "#14141452",
                borderRadius: "40px",
                margin: "80px 30px",
              }}
              className="bodyComponentChildBackdrop"
            >
              <FadeInWhenVisible>
                <motion.div
                  style={{
                    fontWeight: "800",
                    letterSpacing: "1px",
                  }}
                  className="heading"
                >
                  Virtual Labs
                </motion.div>
              </FadeInWhenVisible>
              <Divider
                style={{ width: "90%", height: "5px", margin: "20px 5%" }}
              />
              <FadeInWhenVisible>
                <motion.div
                  style={{
                    letterSpacing: "1px",
                    textAlign: "justify",
                  }}
                  className="description"
                >
                  Not enough experiments for you? Well – let us introduce VLabs!
                  <br />
                  <br />
                  With upwards of 50 experiments in an affordable and convenient
                  package VLabs has everything a student could possibly need to
                  learn the sciences.
                  <br />
                  <br />
                  Our proprietary software allows students to perfectly
                  replicate the experiments done in a virtual world.
                  Furthermore, the inclusion of a plethora of accessibility
                  features means that any and all students can benefit through
                  the use of VLabs!
                  <br />
                  <br />
                  VLabs will offer a innovative and revolutionary way of
                  studying science.
                  <br />
                  <br />
                </motion.div>
              </FadeInWhenVisible>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsComponent;
