export const items = [
  {
    id: "physics",
    category: "...",
    title: "Physics",
    pointOfInterest: 80,
    backgroundColor: "#814A0E"
  },
  {
    id: "chemistry",
    category: "...",
    title: "Chemistry",
    pointOfInterest: 120,
    backgroundColor: "#959684"
  },
  {
    id: "biology",
    category: "...",
    title: "Biology",
    pointOfInterest: 260,
    backgroundColor: "#5DBCD2"
  }
];

export const openSpring = { type: "spring", stiffness: 200, damping: 30 };
