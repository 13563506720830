import sciencekit from "../../images/sekk.jpeg";
import p from "../../images/physics_l.gif";
import c from "../../images/chemistry_l.gif";
import b from "../../images/biology_l.gif";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { Button, Divider } from "@material-ui/core";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { useHistory } from "react-router-dom";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

function FadeInWhenVisible({ children }) {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: { x: 0, opacity: 1 },
        hidden: { x: "100px", opacity: 0 },
      }}
    >
      {children}
    </motion.div>
  );
}

const ScienceExplorerKitComponent = ({ showReadMore }) => {
  const physics = [
    "Ohm's Law proof",
    "Measuring Voltage Drop for a set of resistors (Series - parallel - Series & parallel)",
    "Measuring Electrical Current for a set of resistors (Series - parallel - Series & parallel)",
    "Measuring the resistance (Series - parallel - Series & parallel)",
    "Light (color Dependent reflectivity)",
    "Measuring the Distance between two points",
    "Measuring the Speed of an object at a set of distances",
    "Measuring the free fall between two points",
    "Measuring Weight and mass using the load cell",
    "Measuring the friction force",
    "Capacitors in direct current circuits",
    "Diodes as rectifiers",
    "Newton's First Law",
    "Relationship between a wire cross-sectional area and the wire's resistance",
    "Relationship between a wire length and the wire's resistance",
    "The magnetic effect of a current-carrying conductor",
    "Relationship between speed and acceleration",
  ];
  const chemistry = [
    "Specific heat of fluids",
    "Measuring Electrical conductivity for a solution",
    "Definition of bond type of a solution (Covalent or Ionic)",
    "Measuring of Dissolving substance",
    "PH Measuring",
    "Neutralization / Titration reaction",
    "Electrical conductivity of solutions",
    "Heat Balance Experiment",
    "Dissolving Heat Experiment",
    "Examine water sample from different sources",
    "Determine Specific heat of distilled water",
    "Compare PH of distilled water, tape water and mineral water",
  ];
  const biology = [
    "Measuring ambient temperature and Humidity",
    "Monitoring the CO2 gas concentration of a plant locked in a vessel",
    "Heart Rate",
    "Monitoring light intensity and its effect on photosynthesis",
    "Monitoring the Oxygen gas concentration in of a plant locked in a vessel",
    "Relation between Height and Humidity",
    "Relation between Height and temprature",
    "Heart rate in Rest",
    "Heart rate in Exertion",
  ];
  const TimeLine = () => {
    return (
      <Timeline align="left">
        <TimelineItem>
          <TimelineSeparator>
            <FadeInWhenVisible>
              <TimelineDot color="secondary" />
            </FadeInWhenVisible>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            style={{
              fontSize: "1.5em",
              marginTop: "-7px",
              fontWeight: "500",
              letterSpacing: "1px",
            }}
          >
            <FadeInWhenVisible>30+ Experiments</FadeInWhenVisible>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <FadeInWhenVisible>
              <TimelineDot color="primary" />
            </FadeInWhenVisible>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            style={{
              fontSize: "1.5em",
              marginTop: "-7px",
              fontWeight: "500",
              letterSpacing: "1px",
            }}
          >
            <FadeInWhenVisible>Step by step approach</FadeInWhenVisible>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <FadeInWhenVisible>
              <TimelineDot color="secondary" />
            </FadeInWhenVisible>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            style={{
              fontSize: "1.5em",
              marginTop: "-7px",
              fontWeight: "500",
              letterSpacing: "1px",
            }}
          >
            <FadeInWhenVisible>Grades 9 - 12</FadeInWhenVisible>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <FadeInWhenVisible>
              <TimelineDot color="primary" />
            </FadeInWhenVisible>
          </TimelineSeparator>
          <TimelineContent
            style={{
              fontSize: "1.5em",
              marginTop: "-7px",
              fontWeight: "500",
              letterSpacing: "1px",
            }}
          >
            <FadeInWhenVisible>Affordable</FadeInWhenVisible>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    );
  };

  const history = useHistory();

  return (
    <>
      <div
        style={{
          height: "auto",
          width: "100vw",
          display: "flex",
        }}
        className="bodyComponentReverse"
      >
        <div
          style={{
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="bodyComponentBiggerChild"
        >
          <div
            style={{
              background: "#14141452",
              borderRadius: "40px",
              margin: "80px 30px",
              marginTop: `${!showReadMore ? "120px" : "0"}`,
            }}
            className="bodyComponentChildBackdrop"
          >
            <FadeInWhenVisible>
              <motion.div
                style={{
                  fontWeight: "800",
                  letterSpacing: "1px",
                }}
                className="heading"
              >
                Science Explorer Kit.
              </motion.div>
            </FadeInWhenVisible>
            <Divider
              style={{ width: "90%", height: "5px", margin: "20px 5%" }}
            />
            <div style={{ width: "200%", marginLeft: "-100%" }}>
              <TimeLine />
            </div>
            <FadeInWhenVisible>
              <motion.div
                style={{
                  letterSpacing: "1px",
                  textAlign: "justify",
                }}
                className="description"
              >
                The Science Explorer Kit is a tool of infinite possibilities
                built with passion and care. With over 45 components and 30
                experiments the uses are endless. We have built and curated each
                project and experiment with learning in mind to really recreate
                the experiences a student could have in a real lab.
                <br />
                <br />
                We believe that our kit is the new staple of education in our
                modern circumstances and will come to set new expectations for
                the way science is taught in schools.
                <br />
                <br />
              </motion.div>
            </FadeInWhenVisible>
            {showReadMore && (
              <FadeInWhenVisible>
                <motion.div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => {
                    history.push("/page/explorerkit");
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    className="read-more-button"
                  >
                    Read More
                  </Button>
                </motion.div>
              </FadeInWhenVisible>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="bodyComponentSmallerChild"
        >
          <center className="aboutusimage">
            <FadeInWhenVisible>
              <img src={sciencekit} alt="svg" className="bodyComponentImage" />
            </FadeInWhenVisible>
          </center>
        </div>
      </div>
      {!showReadMore && (
        <>
          <div className="Physics">
            <div
              style={{
                height: "auto",
                width: "100vw",
                display: "flex",
              }}
              className="landing-page"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="bodyComponentSmallerChild"
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#ccc",
                  }}
                >
                  <Timeline align="alternate">
                    {physics.map((item, index) => {
                      // return <Chip label={<Typography style={{whiteSpace: 'normal'}}>{item}</Typography>} style={{margin: '10px', height : '100%', padding: '5px'}} />;
                      return (
                        <TimelineItem key={index}>
                          <TimelineSeparator>
                            <TimelineDot
                              variant="outlined"
                              color={`${
                                index % 2 === 0 ? "primary" : "secondary"
                              }`}
                            />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent
                            style={{
                              fontWeight: "500",
                              letterSpacing: "1px",
                              // textAlign: "center",
                            }}
                          >
                            <FadeInWhenVisible>{item}</FadeInWhenVisible>
                          </TimelineContent>
                        </TimelineItem>
                      );
                    })}
                  </Timeline>
                </div>
              </div>
              <div
                style={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="bodyComponentBiggerChild"
              >
                <div
                  style={{
                    background: "#14141452",
                    borderRadius: "40px",
                    margin: "80px 30px",
                  }}
                  className="bodyComponentChildBackdrop"
                >
                  <FadeInWhenVisible>
                    <motion.div
                      style={{
                        fontWeight: "800",
                        letterSpacing: "1px",
                      }}
                      className="heading"
                    >
                      Physics
                    </motion.div>
                  </FadeInWhenVisible>
                  <Divider
                    style={{ width: "90%", height: "5px", margin: "20px 5%" }}
                  />
                  <FadeInWhenVisible>
                    <motion.div
                      style={{
                        letterSpacing: "1px",
                        textAlign: "justify",
                      }}
                      className="description"
                    >
                      The branch of science concerned with the nature and
                      properties of matter and energy! Physics helps us
                      understand the natural laws which govern our world and
                      dictate the behaviour of all physical objects.
                      <br />
                      <br />
                      Through the use of our revolutionary kit we breach the
                      subject matter of heat, light, sound, electricity and
                      others making them fully accessible to students.
                      <br />
                      <br />
                      <center>
                        <Zoom overlayBgColorEnd="#141414cc">
                          <motion.img
                            whileHover={{ scale: 1.1 }}
                            src={p}
                            style={{
                              height: "40vh",
                              borderRadius: "30px",
                              cursor: "pointer",
                            }}
                            alt=""
                          />
                        </Zoom>
                      </center>
                    </motion.div>
                  </FadeInWhenVisible>
                </div>
              </div>
            </div>
          </div>

          <div className="Chemistry">
            <div
              style={{
                height: "auto",
                width: "100vw",
                display: "flex",
              }}
              className="bodyComponentReverse"
            >
              <div
                style={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="bodyComponentBiggerChild"
              >
                <div
                  style={{
                    background: "#14141452",
                    borderRadius: "40px",
                    margin: "80px 30px",
                  }}
                  className="bodyComponentChildBackdrop"
                >
                  <FadeInWhenVisible>
                    <motion.div
                      style={{
                        fontWeight: "800",
                        letterSpacing: "1px",
                      }}
                      className="heading"
                    >
                      Chemistry
                    </motion.div>
                  </FadeInWhenVisible>
                  <Divider
                    style={{ width: "90%", height: "5px", margin: "20px 5%" }}
                  />
                  <FadeInWhenVisible>
                    <motion.div
                      style={{
                        letterSpacing: "1px",
                        textAlign: "justify",
                      }}
                      className="description"
                    >
                      When you think Chemistry, you think labs! It is the study
                      of matter, defined as anything that has mass and takes up
                      space, and the changes that matter can undergo when it is
                      subject to different environments and conditions.
                      <br />
                      <br />
                      Chemistry seeks to understand not only the properties of
                      matter, like the mass or composition of a chemical
                      element, but also how and why matter undergoes certain
                      changes.
                      <br />
                      <br />
                      <center>
                        <Zoom overlayBgColorEnd="#141414cc">
                          <motion.img
                            whileHover={{ scale: 1.1 }}
                            src={c}
                            style={{
                              height: "40vh",
                              borderRadius: "30px",
                              cursor: "pointer",
                            }}
                            alt=""
                          />
                        </Zoom>
                      </center>
                    </motion.div>
                  </FadeInWhenVisible>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="bodyComponentSmallerChild"
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#ccc",
                  }}
                >
                  <Timeline align="alternate">
                    {chemistry.map((item, index) => {
                      // return <Chip label={<Typography style={{whiteSpace: 'normal'}}>{item}</Typography>} style={{margin: '10px', height : '100%', padding: '5px'}} />;
                      return (
                        <TimelineItem key={index}>
                          <TimelineSeparator>
                            <TimelineDot
                              variant="outlined"
                              color={`${
                                index % 2 === 0 ? "primary" : "secondary"
                              }`}
                            />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent
                            style={{
                              fontWeight: "500",
                              letterSpacing: "1px",
                              // textAlign: "center",
                            }}
                          >
                            <FadeInWhenVisible>{item}</FadeInWhenVisible>
                          </TimelineContent>
                        </TimelineItem>
                      );
                    })}
                  </Timeline>
                </div>
              </div>
            </div>
          </div>

          <div className="Biology">
            <div
              style={{
                height: "auto",
                width: "100vw",
                display: "flex",
              }}
              className="landing-page"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="bodyComponentSmallerChild"
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#ccc",
                  }}
                >
                  <Timeline align="alternate">
                    {biology.map((item, index) => {
                      // return <Chip label={<Typography style={{whiteSpace: 'normal'}}>{item}</Typography>} style={{margin: '10px', height : '100%', padding: '5px'}} />;
                      return (
                        <TimelineItem key={index}>
                          <TimelineSeparator>
                            <TimelineDot
                              variant="outlined"
                              color={`${
                                index % 2 === 0 ? "primary" : "secondary"
                              }`}
                            />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent
                            style={{
                              fontWeight: "500",
                              letterSpacing: "1px",
                              // textAlign: "center",
                            }}
                          >
                            <FadeInWhenVisible>{item}</FadeInWhenVisible>
                          </TimelineContent>
                        </TimelineItem>
                      );
                    })}
                  </Timeline>
                </div>
              </div>
              <div
                style={{
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="bodyComponentBiggerChild"
              >
                <div
                  style={{
                    background: "#14141452",
                    borderRadius: "40px",
                    margin: "80px 30px",
                  }}
                  className="bodyComponentChildBackdrop"
                >
                  <FadeInWhenVisible>
                    <motion.div
                      style={{
                        fontWeight: "800",
                        letterSpacing: "1px",
                      }}
                      className="heading"
                    >
                      Biology
                    </motion.div>
                  </FadeInWhenVisible>
                  <Divider
                    style={{ width: "90%", height: "5px", margin: "20px 5%" }}
                  />
                  <FadeInWhenVisible>
                    <motion.div
                      style={{
                        letterSpacing: "1px",
                        textAlign: "justify",
                      }}
                      className="description"
                    >
                      Biology is the scientific study of life - the study of
                      living organisms, divided into many specialized fields
                      that cover their morphology, physiology, anatomy,
                      behaviour, origin, and distribution.
                      <br />
                      <br />
                      Through the use of our kit we help students get more in
                      touch with the behavior of plants, temperature and others.
                      <br />
                      <br />
                      <center>
                        <Zoom overlayBgColorEnd="#141414cc">
                          <motion.img
                            whileHover={{ scale: 1.1 }}
                            src={b}
                            style={{
                              height: "40vh",
                              borderRadius: "30px",
                              cursor: "pointer",
                            }}
                            alt=""
                          />
                        </Zoom>
                      </center>
                    </motion.div>
                  </FadeInWhenVisible>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ScienceExplorerKitComponent;
