// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyANUdemafM-ad2CLVf0jxxZ7qhmn0Qb8L4",
  authDomain: "labsforhome.firebaseapp.com",
  projectId: "labsforhome",
  storageBucket: "labsforhome.appspot.com",
  messagingSenderId: "767861422639",
  appId: "1:767861422639:web:9866f13413b13d9721e4da",
  measurementId: "G-14Q7QQ6594",
};

const firebaseApp = initializeApp(firebaseConfig);
  
const db = getFirestore();

export { db, firebaseApp };
