import React, { useState } from "react";
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import "./InteractiveBlock.css";
import circuit from "../../images/circuit.svg";
import white_bubble from "../../images/bubble_white.png";
import white_bubble_vl from "../../images/bubble_white_vl.png";
import { List } from "../SubjectCards/List";
import { Item } from "../SubjectCards/Item";
import { IconButton, Button, ButtonBase } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { scroller } from "react-scroll";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LaunchIcon from "@material-ui/icons/Launch";

const InteractiveBlock = ({ match }) => {
  let { id } = match.params;
  const history = useHistory();
  const [showSek, setShowSek] = useState(false);
  const [showOne, setShowOne] = useState(false);
  const [showFive, setShowFive] = useState(false);
  React.useEffect(() => {
    console.log(localStorage.getItem('showSek'))
    if(localStorage.getItem('showSek')!==null) { 
      setTimeout(() => {
        setShowSek(true);
        localStorage.removeItem('showSek');
      }, 500);
    }
    if(localStorage.getItem('showFive')!==null) { 
      setTimeout(() => {
        setShowFive(true);
        localStorage.removeItem('showFive');
      }, 1000);
    }
    if(localStorage.getItem('showOne')!==null) { 
      setTimeout(() => {
        setShowOne(true);
        localStorage.removeItem('showOne');
      }, 1000);
    }
  }, [])

  return (
    <AnimateSharedLayout type="crossfade">
      <AnimatePresence>
        <motion.div
          style={{
            width: "100vw",
            minHeight: "100vh",
            display: "flex",
            flexDirection:
              showSek || match.params.section !== undefined ? "column" : "row",
            background: "#141414",
          }}
          className="ibcontainer"
        >
          {match.params.section === undefined && (
            <>
              {!showSek ? (
                <motion.div
                  className="sek_container"
                  layoutId="sek_container"
                  id="sek_container"
                  onClick={() => {
                    setShowSek(true);
                    history.push("/");
                  }}
                  style={{ opacity: "0.5" }}
                >
                  <motion.img
                    layoutId="comic_image"
                    src={white_bubble}
                    alt=""
                    style={{
                      width: "100%",
                    }}
                  />
                </motion.div>
              ) : (
                <motion.div
                  id="sek_container_open"
                  style={{
                    width: "100vw",
                    height: "50vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    opacity: "1",
                  }}
                  layoutId="sek_container"
                >
                  <motion.div
                    style={{
                      position: "absolute",
                      top: "120px",
                      left: "3vw",
                    }}
                    onClick={() => {
                      setShowSek(false);
                      history.push("/");
                    }}
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    // animate={{ scale: [0, 1.3, 1] }}
                  >
                    <motion.div
                      animate={{
                        rotate: [0, 45, 0],
                        borderRadius: [0, 10, 100],
                      }}
                      style={{
                        width: "8vh",
                        height: "8vh",
                        background: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "5px solid #141414",
                      }}
                    >
                      <ButtonBase
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                        }}
                      >
                        <ArrowBackIcon style={{ fontSize: "4.5em" }} />
                      </ButtonBase>
                    </motion.div>
                  </motion.div>
                  <motion.img
                    src={white_bubble}
                    alt=""
                    layoutId="comic_image"
                    style={{
                      height: "70%",
                    }}
                  />
                </motion.div>
              )}
            </>
          )}
          <motion.img
            src={circuit}
            style={{
              width: "0",
            }}
            alt=""
          />
          {showSek && match.params.section === undefined && (
            <motion.img
              className="circuit"
              layoutId="big_circuit"
              src={circuit}
              style={{
                width: "90vw",
                marginTop: "-100px",
                marginBottom: "-8%",
                marginLeft: "5vw",
                zIndex: "0",
              }}
              alt=""
              animate={{ scale: [0, 1.1, 1] }}
              exit={{ scale: [1, 0] }}
            />
          )}
          {!showSek && match.params.section === undefined && (
            <>
              <motion.div
                id="vlabs_container"
                className="sek_container"
                layoutId="vlabs_container"
                onClick={() => {
                  history.push("/");
                  scroller.scrollTo("vlabsComponent", {
                    duration: 1000,
                    spy: true,
                    smooth: true,
                    offset: -80,
                  });
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    background: "#14141400",
                  }}
                  className="sek_container"
                >
                  <img
                    src={white_bubble_vl}
                    alt=""
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
              </motion.div>
            </>
          )}
          {showSek && match.params.section === undefined && (
            <div className="subjects-container">
              <List selectedId={id} />
              <AnimatePresence>
                {id && match.path === "/card/:id" && (
                  <Item id={id} key="item" />
                )}
                {/* {match.path === "/card/:id" && <CloseButton />} */}
              </AnimatePresence>
            </div>
          )}
          {match.params.id === "Physics" && (
            <motion.div
              layoutId="Physics"
              style={{
                width: "100vw",
                height: "50vh",
                backgroundImage: 'url("/images/physics.gif")',
                backgroundPosition: "100% 80%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <motion.div
                style={{
                  position: "absolute",
                  top: "120px",
                  left: "3vw",
                }}
                onClick={() => {
                  history.push("/");
                }}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
                // animate={{ scale: [0, 1.3, 1] }}
              >
                <motion.div
                  animate={{
                    rotate: [0, 45, 0],
                    borderRadius: [0, 10, 100],
                  }}
                  style={{
                    width: "8vh",
                    height: "8vh",
                    background: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "5px solid #141414",
                  }}
                >
                  <ButtonBase
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                    }}
                  >
                    <ArrowBackIcon style={{ fontSize: "4.5em" }} />
                  </ButtonBase>
                </motion.div>
              </motion.div>
              <div
                style={{
                  background: "#14141433",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                <motion.h1 className="interactive_heading">Physics</motion.h1>
              </div>
              <motion.div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                  history.push("/page/explorerkit/Physics");
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  className="read-more-button"
                >
                  Read More
                </Button>
              </motion.div>
            </motion.div>
          )}
          {match.params.id === "Chemistry" && (
            <motion.div
              layoutId="Chemistry"
              style={{
                width: "100vw",
                height: "50vh",
                backgroundImage: 'url("/images/chemistry.gif")',
                backgroundPosition: "100% 50%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <motion.div
                style={{
                  position: "absolute",
                  top: "120px",
                  left: "3vw",
                }}
                onClick={() => {
                  history.push("/");
                }}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
                // animate={{ scale: [0, 1.3, 1] }}
              >
                <motion.div
                  animate={{
                    rotate: [0, 45, 0],
                    borderRadius: [0, 10, 100],
                  }}
                  style={{
                    width: "8vh",
                    height: "8vh",
                    background: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "5px solid #141414",
                  }}
                >
                  <ButtonBase
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                    }}
                  >
                    <ArrowBackIcon style={{ fontSize: "4.5em" }} />
                  </ButtonBase>
                </motion.div>
              </motion.div>
              <div
                style={{
                  background: "#14141433",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                <motion.h1 className="interactive_heading">Chemistry</motion.h1>
              </div>
              <motion.div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                  history.push("/page/explorerkit/Chemistry");
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  className="read-more-button"
                >
                  Read More
                </Button>
              </motion.div>
            </motion.div>
          )}
          {match.params.id === "Biology" && (
            <motion.div
              layoutId="Biology"
              style={{
                width: "100vw",
                height: "50vh",
                backgroundImage: 'url("/images/biology.gif")',
                backgroundPosition: "100% 50%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <motion.div
                style={{
                  position: "absolute",
                  top: "120px",
                  left: "3vw",
                }}
                onClick={() => {
                  history.push("/");
                }}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
                // animate={{ scale: [0, 1.3, 1] }}
              >
                <motion.div
                  animate={{
                    rotate: [0, 45, 0],
                    borderRadius: [0, 10, 100],
                  }}
                  style={{
                    width: "8vh",
                    height: "8vh",
                    background: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "5px solid #141414",
                  }}
                >
                  <ButtonBase
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                    }}
                  >
                    <ArrowBackIcon style={{ fontSize: "4.5em" }} />
                  </ButtonBase>
                </motion.div>
              </motion.div>
              <div
                style={{
                  background: "#14141433",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                <motion.h1 className="interactive_heading">Biology</motion.h1>
              </div>
              <motion.div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                  history.push("/page/explorerkit/Biology");
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  className="read-more-button"
                >
                  Read More
                </Button>
              </motion.div>
            </motion.div>
          )}
          {match.params.section !== undefined && (
            <motion.div
              style={{
                width: "100vw",
                height: "auto",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                paddingTop: "30px",
              }}
              className="grades-container"
            >
              <motion.div
                style={{ width: "30%", height: "40vh" }}
                whileHover={{ scale: 1.1 }}
                className="grades-card"
              >
                <motion.div
                  animate={{
                    borderRadius: [10, 30],
                    scale: [0.5, 1],
                    opacity: [0, 1],
                  }}
                  transition={{ duration: 1 }}
                  style={{
                    height: "100%",
                    backgroundImage: 'url("/images/9-12.jpeg")',
                    backgroundPosition: "100% 50%",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    // cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push(`/page/explorerkit/${match.params.id}`);
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      background: "#14141483",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h1
                      style={{
                        fontSize: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Grades 9-12{" "}
                      <LaunchIcon
                        style={{ fontSize: "inherit", marginLeft: "10px" }}
                      />
                    </h1>
                  </div>
                </motion.div>
              </motion.div>
              {!showFive && (
                <motion.div
                  style={{ width: "30%", height: "40vh" }}
                  whileHover={{ scale: 1.1 }}
                  layoutId="five-outer"
                  className="grades-card"
                >
                  <motion.div
                    animate={{
                      borderRadius: [10, 30],
                      scale: [0.5, 1],
                      opacity: [0, 1],
                    }}
                    style={{
                      height: "100%",
                      backgroundImage: 'url("/images/5-8.jpeg")',
                      backgroundPosition: "100% 50%",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      // cursor: "pointer",
                    }}
                    layoutId="five-container"
                    onClick={() => {
                      // history.push(`/subject/${match.params.id}/5-8`);
                      setShowFive(true);
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        background: "#14141483",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h1
                        style={{
                          fontSize: "50px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Grades 5-8{" "}
                        <LaunchIcon
                          style={{ fontSize: "inherit", marginLeft: "10px" }}
                        />
                      </h1>
                    </div>
                  </motion.div>
                </motion.div>
              )}
              <motion.div
                style={{ width: "30%", height: "40vh" }}
                whileHover={{ scale: 1.1 }}
                layoutId={`one-outer`}
                className="grades-card"
              >
                {!showOne && (
                  <motion.div
                    animate={{
                      borderRadius: [10, 30],
                      scale: [0.5, 1],
                      opacity: [0, 1],
                    }}
                    style={{
                      height: "100%",
                      backgroundImage: 'url("/images/1-4.jpeg")',
                      backgroundPosition: "100% 50%",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      // cursor: "pointer",
                    }}
                    onClick={() => {
                      // history.push(`/subject/${match.params.id}/1-4`);
                      setShowOne(true);
                    }}
                    layoutId="one-container"
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        background: "#14141483",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h1
                        style={{
                          fontSize: "50px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Grades 1-4{" "}
                        <LaunchIcon
                          style={{ fontSize: "inherit", marginLeft: "10px" }}
                        />
                      </h1>
                    </div>
                  </motion.div>
                )}
              </motion.div>
            </motion.div>
          )}
          {showOne === true && (
            <>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, transition: { duration: 0.15 } }}
                transition={{ duration: 0.2, delay: 0.15 }}
                className="overlay"
                onClick={() => {
                  setShowOne(false);
                }}
              ></motion.div>
              <div className="card-content-container open">
                <motion.div className="card-content" layoutId={`one-outer`}>
                  <div
                    onClick={() => {
                      setShowOne(false);
                    }}
                  >
                    <motion.div className="card-image-container">
                      <motion.img
                        style={{ maxWidth: "700px" }}
                        src="/images/1-4.jpeg"
                        alt=""
                        layoutId="one-container"
                      />
                    </motion.div>
                  </div>
                  <motion.div
                    className="title-container"
                    layoutId={`title-container-${id}`}
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      background: "#14141483",
                      color: "white",
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                  >
                    <motion.div
                      onClick={() => {
                        setShowOne(false);
                      }}
                      style={{ padding: "10px" }}
                      animate={{ scale: [0, 1.3, 1] }}
                      transition={{ duration: 0.8 }}
                    >
                      <IconButton size="medium" style={{ background: "white" }}>
                        <ArrowBackIcon />
                      </IconButton>
                    </motion.div>
                    <h2>Grades 1-4</h2>
                  </motion.div>
                  <br />
                  <motion.div className="content-container" animate>
                    It is crucial to foster the love of science from a young
                    age! We believe our elementary kit will provide kids the
                    tools necessary to grow their love of science and get a good
                    grasp of the scientific concepts that govern their world.
                    <br />
                    <br />
                    Science education activities provide children with
                    opportunities to develop and practice many different skills
                    and attributes. These include communication skills,
                    collaborative skills, team working and perseverance, as well
                    as analytical, reasoning and problem-solving skills.
                    <br />
                    <br />
                    We will be revealing our elementary kit in the near future
                    so keep those eyes peeled!
                  </motion.div>
                </motion.div>
              </div>
            </>
          )}
          {showFive === true && (
            <>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, transition: { duration: 0.15 } }}
                transition={{ duration: 0.2, delay: 0.15 }}
                className="overlay"
                onClick={() => {
                  setShowFive(false);
                }}
              ></motion.div>
              <div className="card-content-container open">
                <motion.div className="card-content" layoutId={`five-outer`}>
                  <div
                    onClick={() => {
                      setShowFive(false);
                    }}
                  >
                    <motion.div className="card-image-container">
                      <motion.img
                        style={{ maxWidth: "700px" }}
                        src="/images/5-8.jpeg"
                        alt=""
                        layoutId="five-container"
                      />
                    </motion.div>
                  </div>
                  <motion.div
                    className="title-container"
                    layoutId={`title-container-${id}`}
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      background: "#14141483",
                      color: "white",
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                  >
                    <motion.div
                      onClick={() => {
                        setShowFive(false);
                      }}
                      style={{ padding: "10px" }}
                      animate={{ scale: [0, 1.3, 1] }}
                      transition={{ duration: 0.8 }}
                    >
                      <IconButton size="medium" style={{ background: "white" }}>
                        <ArrowBackIcon />
                      </IconButton>
                    </motion.div>
                    <h2>Grades 5-8</h2>
                  </motion.div>
                  <br />
                  <motion.div className="content-container" animate>
                    Teaching the scientific method to students is teaching them
                    how to think, learn, solve problems and make informed
                    decisions. These skills are integral to every aspect of a
                    student’s education and life, from school to career.
                    <br />
                    <br />
                    We think that each and every kid should pick up these skills
                    in a way which is fun and engaging further deepening their
                    relationship with the sciences. These skills will help
                    students as they navigate through both the real world and
                    the many academic realms.
                    <br />
                    <br />
                    Save this space for something big coming soon with our
                    Middle-school Kit!
                  </motion.div>
                </motion.div>
              </div>
            </>
          )}
        </motion.div>
      </AnimatePresence>
    </AnimateSharedLayout>
  );
};

export default InteractiveBlock;
